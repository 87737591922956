import React from 'react';
import { graphql } from 'gatsby';
import DemoBanner from '../../components/DemoBanner';
import DeviceHero from '../../components/DeviceHero';
import DeviceSection from '../../components/DeviceSection';
import Layout from '../../components/Layout';
import ResourcesSection from '../../components/ResourcesSection';
import SideBySideSection from '../../components/SideBySideSection';
import Wayfinding from '../../components/Wayfinding';
import { deviceDataFromDeviceAsset } from '../../utils/contentfulHelpers';
import SiteMeta from '../../components/SiteMeta';

const CreatePage = ({ data, location }) => {
  const {
    metaTitle,
    metaDescription: { metaDescription },
    heroModule,
    createIntroductionModule,
    createInfoModule1,
    createInfoModule2,
    createInfoModule3,
    demoCallout,
    wayfindingCardTitle,
    wayfindingCardSubheader: { wayfindingCardSubheader },
    wayfindingCards,
    featuredResources,
    featuredResourcesTitle,
    featuredResourcesSubheader: { featuredResourcesSubheader },
    featuredResourcesCtaText,
    featuredResourcesCtaLink,
  } = data.contentfulCreatePage;

  const heroDeviceData = deviceDataFromDeviceAsset(heroModule.deviceAsset);

  const wayfindingData = {
    title: wayfindingCardTitle,
    subtitle: wayfindingCardSubheader,
    items: wayfindingCards.map(
      ({ wayfindingCardTitle, wayfindingCardBody, matchUrl }) => {
        return {
          title: wayfindingCardTitle,
          body: wayfindingCardBody.wayfindingCardBody,
          matchUrl,
        };
      },
    ),
    currentPath: location.pathname,
  };


  // TODO Add Resources to the CMS
  const resourcesData = {
    title: featuredResourcesTitle,
    subtitle: featuredResourcesSubheader,
    ctaText: featuredResourcesCtaText,
    ctaUrl: featuredResourcesCtaLink,
    featuredResources: featuredResources
  };

  const deviceSections = [
    createInfoModule1,
    createInfoModule2,
    createInfoModule3
  ]

  return (
    <Layout>
      <SiteMeta title={metaTitle} description={metaDescription} />
      <DeviceHero
        title={heroModule.title}
        body={heroModule.body}
        {...heroDeviceData}
      />
      <SideBySideSection barTheme={'transparent'} layoutVariant={'mediaRight'} {...createIntroductionModule} />
      {deviceSections.map((section, index) => {
        const layout = index % 2 ? 'deviceRight' : 'deviceLeft';
        const deviceData = deviceDataFromDeviceAsset(section.deviceAsset)
        return (
          <DeviceSection {...section} {...deviceData} key={index} layoutVariant={layout} />
        );
      })}
      <Wayfinding {...wayfindingData} />
      <DemoBanner {...demoCallout} theme="dark" />
      <ResourcesSection {...resourcesData} />
    </Layout>
  );
};

export default CreatePage;

export const query = graphql`
  query {
    contentfulCreatePage {
      metaTitle,
      metaDescription{
        metaDescription
      }
      heroModule {
        ...DeviceHero
      }
      createIntroductionModule {
        ...Navigator
      }
      createInfoModule1 {
        ...DeviceNavigator
      }
      createInfoModule2 {
        ...DeviceNavigator
      }
      createInfoModule3 {
        ...DeviceNavigator
      }
      demoCallout {
        ...Navigator
      }
      wayfindingCardTitle
      wayfindingCardSubheader {
        wayfindingCardSubheader
      }
      wayfindingCards {
        ...WayfindingCard
      }
      featuredResources {
        ...ResourceCard
      }
      featuredResourcesTitle
      featuredResourcesSubheader {
        featuredResourcesSubheader
      }
      featuredResourcesCtaText
      featuredResourcesCtaLink
    }
  }
`;
